<template src="./persons.htm">
 


</template>
<script>
import { mapState } from 'vuex';
import Footer from '@/layouts/footer/Footer';
import Form from '@/utils/form'
import SubHeader from '@/layouts/sub_header/Subheader.vue';

export default {
  name: "Persons",
  components: {
      'footer-component': Footer,
      'sub-header': SubHeader,
  },
  data: () => ({
    valid: true,
    name: '',
    id: '',
    ids: [],
    selected: [],
    addperson_dialog: false,
    select_rules:[
      v => !!v || 'Item is required'
    ],
    Field_Rules: [
          v => !!v || 'Required',
        ],
    contact_dialog: false,
    secondary_dialog: false,
    summary_dialog: false,
    view_table: true,
    view_info: false,
    add_receiver: false,
    users: [],
    tags: [],
    location: [],
    is_admin: '',
    person: {
      id: '',
      name: '',
      status: '',
      contact: '',
      address: '',
      serial: '',
      receiver_id: '',
      secondary_name: '',
      secondary_contact: ''
    },
    userprofile: [
        { title: "My Profile" },
        { title: "My Balance" },
        { title: "Inbox" },
        { title: "Account Setting" },
        { title: "Logout" }
    ],
    searchParams: {
        name: '',
    },
    initForm: new Form({
      id: '',
      name: '',
      location: '',
      serial: '',
      tag_id: '',
      mobile_number: '',
      street_address: '',
      barangay: '',
      municipality: '',
      province: '',
      secondary_full_name: '',
      secondary_relationship: '',
      secondary_mobile: '',
    }),
    table_header: [
          { 
            text: 'Wearable Number',
            align: 'start',
            sortable: false,
            value: 'wearable',
          },
          { text: 'Person', value: 'name' },
          
        ],
    overlay: true,
  }),
  computed: {
    ...mapState({
        user: state => state.auth.user,
        isAuthenticated: state => state.auth.isAuthenticated,
    }),
  },
  methods: {
    resetValidation (){
      this.$refs.addperson_Form.resetValidation()
    },
    submit_personForm (){
      if(this.$refs.addperson_Form.validate()){
        this.addperson_dialog=!this.addperson_dialog,
        this.contact_dialog=!this.contact_dialog
      }
      
    },
    submit_contactForm (){
      if(this.$refs.contact_form.validate()){
        this.contact_dialog = false,this.secondary_dialog=true
      }
      
    },
    delete_selected (){
      var result = confirm("Are you sure you want to delete?");
      if (result==true) {

        this.ids = this.selected.map(person => {
          return person.id;
        });

        this.$api
          .post('/person/delete', {ids: this.ids})
          .then(res => {
              
              this.$toast.open({
                  message:  res.data.msg,
                  type: 'success',
                  position: 'bottom'

              }),
              this.search();
          })
          .catch(err => {

              this.$toast.open({
                  message:  err.response.data.msg,
                  type: 'error',
                  position: 'bottom'
              })
          })
      }
    },
    save() {
      if(this.$refs.edit_form.validate()){
        console.log(this.initForm);
         this.$api
         .put(`/person/${this.initForm.id}`, this.initForm)
            .then(res => {
                this.$toast.open({
                    message:  'Successfully Submitted!',
                    type: 'success',
                    position: 'bottom'
                })

                this.search();
                this.summary_dialog = false;
                this.resetValidation();
            })
            .catch(err => {
                this.$toast.open({
                    message:  err.response.data.msg,
                    type: 'error',
                    position: 'bottom'
                })
            })
      }else {
        this.$toast.open({
          message:  "Incomplete Form!!!",
          type: 'error',
          position: 'bottom'
        })
      }
        
    },
    SummaryView (val) {
      console.log(val)
      this.summary_dialog = true

      this.getPersonSecondaryContact(val.id);
      this.setData(val);
      console.log(this.initForm);
    },
    setData(data) {
      this.initForm.id = data.id
      this.initForm.name = data.name;
      this.initForm.tag_id = data.tag_id;
      this.initForm.mobile_number = data.mobile_number;
      this.initForm.street_address = data.street_address;
      this.initForm.barangay = data.barangay;
      this.initForm.municipality = data.municipality;
      this.initForm.province = data.province;
    },
    getPerson(id) {
      this.$api
          .get('/person/'+id)
          .then(res => {
            let data = res.data;
            
            if(data) {
              this.person.id = data.id;
              this.person.name = data.name;
              this.person.serial = data.serial;
              this.person.status = data.status;
              this.person.receiver_id = data.receiver_id;
              this.person.contact = data.mobile_number;
              this.person.address = data.street_address+' '+data.barangay+' '+data.municipality+', '+data.province;

              this.initForm.tag_id = data.tag_id;
            }

          })
          .catch(err => {
              this.$toast.open({
                  message:  err.response.data.msg,
                  type: 'error',
                  position: 'bottom'
              })
          })
    },
    getLocation() {
      this.overlay = true;
      this.$api
          .get('/location')
          .then(res => {
            this.location = res.data.data;
            if(this.location.length == 0) {
                  this.location = [{
                    serial: 'No Location available',
                    id: '0',
                    disable: true
                  }]
                }
                this.overlay = false;
          })
          .catch(err => {
              this.overlay = false;
              this.$toast.open({
                  message:  err.response.data.msg,
                  type: 'error',
                  position: 'bottom'
              })
          })
    },
    getPersonSecondaryContact(id) {
       this.$api
          .get('/person/'+id+'/secondary-contact')
          .then(res => {
            let data = res.data;

            if(data) {
              this.initForm.secondary_full_name = data.full_name;
              this.initForm.secondary_relationship = data.relationship;
              this.initForm.secondary_mobile = data.contact_number;
            }
          })
          .catch(err => {
              this.$toast.open({
                  message:  err.response.data.msg,
                  type: 'error',
                  position: 'bottom'
              })
          })
    },
      search() {
        let vm = this;
        vm.overlay = true;
        if(!vm.searchParams.name) {
          vm.searchParams.name = '';
        }
        
        this.$api
            .get('/person/search?name='+ vm.searchParams.name)
            .then(res => {
                this.users = res.data.data;
                vm.overlay = false;
            })
            .catch(err => {
                this.$toast.open({
                    message:  err.response.data.msg,
                    type: 'error',
                    position: 'bottom'
                })
            });
        vm.overlay = false;
    },
    tagsList() {
      this.$api
         .get('/tag/search?person=available')
            .then(res => {
                this.tags = res.data.data;
                if(this.tags.length == 0) {
                  this.tags = [{
                    serial: 'No Wearable available',
                    id: '0',
                    disable: true
                  }]
                }
            })
            .catch(err => {
                this.$toast.open({
                    message:  err.response.data.msg,
                    type: 'error',
                    position: 'bottom'
                })
            })
    },
    submit() {
     if(this.$refs.secondary_form.validate()){
        this.$api
         .post('/person', this.initForm)
            .then(res => {
                console.log(res);
                this.$toast.open({
                    message:  'Successfully Submitted!',
                    type: 'success',
                    position: 'bottom'
                })

                this.search();
                this.secondary_dialog = false;
                this.resetValidation();
            })
            .catch(err => {
                this.$toast.open({
                    message:  err.response.data.msg,
                    type: 'error',
                    position: 'bottom'
                })
            })
      }
       
    },
    init() {
      this.search();
      this.tagsList();
      this.getLocation();

    }

  },
  created(){
      this.overlay = true;
  },
  mounted() {

    this.init()
    this.name = this.user.name;
    this.is_admin = this.user.is_admin;
    if (!this.isAuthenticated) {
      this.$router.replace({ name: 'auth.login' })
    }
  },
}
 </script>